import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';

export class RestInterceptor implements HttpInterceptor {

    intercept( req: HttpRequest<any>, next: HttpHandler ): Observable< HttpEvent<any> > {

        const token: string = localStorage.getItem('XXTOKENXX') || '';

        if ( token ) {
            req = req.clone({
                    setHeaders : {
                        Authorization : token,

                        // 'Access-Control-Allow-Origin' : '*'
                    }
                }
            );
        }

        return next.handle( req );
    }
}

enum HttpRequestType {
    GET,
    POST, 
    DELETE,
    PUT,
    HEAD,
    PATCH,
    OPTIONS, 
    MULTIPARTPOST
}

export default HttpRequestType;
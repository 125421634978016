import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import HttpRequestType from '../utils/enums/http-request-type.enum';
import ApiResponse from '../utils/models/api-response.model';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    serviceUrl: string;






    /*
    *
    */
    constructor(
        private Http: HttpClient
    ){
        this.serviceUrl = environment.serviceUrl;
    }






    /*
    *
    */
    public get = <T>( endpoint: string, query?: object ) =>
        this.sendRequest<T>( HttpRequestType.GET, endpoint, query )

    /*
    *
    */
    public post = <T>( endpoint: string, body: any, query?: object | null, headers?: HttpHeaders ) =>
        this.sendRequest<T>( HttpRequestType.POST, endpoint, query, body, headers )

    /*
    *
    */
    public delete = <T>( endpoint: string, query?: object ) =>
        this.sendRequest<T>( HttpRequestType.DELETE, endpoint, query )

    /*
    *
    */
    public put = <T>( endpoint: string, body: any, query?: object ) =>
        this.sendRequest<T>( HttpRequestType.PUT, endpoint, query, body )

    /*
    *
    */
    public head = <T>( endpoint: string, query?: object ) =>
        this.sendRequest<T>( HttpRequestType.HEAD, endpoint, query )

    /*
    *
    */
    public patch = <T>( endpoint: string, body: any, query?: object ) =>
        this.sendRequest<T>( HttpRequestType.PATCH, endpoint, query, body )

    /*
    *
    */
    public options = <T>( endpoint: string ) =>
        this.sendRequest<T>( HttpRequestType.OPTIONS, endpoint )

    /*
    *
    */
    public multipartpost = <T>( endpoint: string, body: any, query?: object ) =>
        this.sendRequest<T>( HttpRequestType.MULTIPARTPOST, endpoint, query, body )






    /*
     * INVIO DI UNA HTTP REQUEST
     */
    private sendRequest<T>(reqType: HttpRequestType, endpoint: string, query: object | null = null, body: any = null, headers?: HttpHeaders ): Observable<T> {
      // Recupero il token dal local storage

      const options = {
        headers : headers || {}
       };

       if(!(reqType == HttpRequestType.MULTIPARTPOST)){

        // @ts-ignore
          if ( !options.headers['Content-Type'] ) {
            // @ts-ignore
            options.headers['Content-Type'] = 'application/json';
          }

       }

       options.headers['client_secret'] = '2Wtx5bbWdPzIqw8SIKg9I4Kjl';
       options.headers['client_id'] = 'MXlfJdsHVkT3KfyuQaIbKLAL6';


      endpoint = environment.serviceUrl?.concat(endpoint);

      if ( !endpoint ){
        return of(   );
      }

      // formatto l'endpoint
      if (query) {
        endpoint = endpoint.concat(this.serializeParameters(query));
      }

      let ob$: Observable< T | any >;

      switch (reqType) {
        case HttpRequestType.GET:
          ob$ = this.Http.get     <T>  (endpoint, options);
          break;
        case HttpRequestType.POST:
          ob$ = this.Http.post    <T> ( endpoint, body, options);
          break;
        case HttpRequestType.DELETE:
          ob$ = this.Http.delete  <T> ( endpoint, options);
          break;
        case HttpRequestType.PUT:
          ob$ = this.Http.put     <T> ( endpoint, body, options);
          break;
        case HttpRequestType.HEAD:
          ob$ = this.Http.head    <T> ( endpoint, options);
          break;
        case HttpRequestType.PATCH:
          ob$ = this.Http.patch   <T> ( endpoint, body, options);
          break;
        case HttpRequestType.OPTIONS:
          ob$ = this.Http.options <T> ( endpoint, options);
          break;
        case HttpRequestType.MULTIPARTPOST:
          ob$ = this.Http.post    <T> ( endpoint, body, options);
          break;
      }

      return ob$.pipe(
            // Recupero il json della risposta XHR
            map( response => response?.json ? response.json() : response ),

            // Recupero la risposta del servizio e controllo se lo stato e' 200 altrimenti lo lancio come errore
            switchMap(
                        ( response: ApiResponse<any> ) =>
                          {
                            if ( response?.Status ) {
                              return response.Status === 200 ? of(response.Data) : throwError( response.ErrorMessage );
                            }
                            else {
                              return of(response);
                            }
                          }

                     ),

            // Catturo l'errore restituito dall'HTTP o dallo switchMap precedente
            catchError( this.handleError )
        );
    }






    /*
    * Gestione dell'error
    */
    private handleError(httpError: HttpErrorResponse | string ): Observable<never> {
      let errorResponse = '';

      if ( httpError instanceof HttpErrorResponse ){
        const error = httpError?.error;

        if ( error?.error === 'invalid_grant' ) {
          errorResponse = error?.error_description;
        }

        if ( error?.type === 'error' ) {
          errorResponse = 'Richiesta non gestita dal server.';
        }

        // else if( httpError.status === 0 )
        if ( errorResponse === '') {
            errorResponse = 'Servizio non raggiungibile!';
        }
      }else{
        errorResponse = httpError;
      }


      return throwError(errorResponse);
    }






    /*
    *   Funzione per serializzare un oggetto in query string per le chiamate XHR
    *   {simo: "simo", bho : "ciao"}  ====> ?simo=simo&bho=ciao
    */
    private serializeParameters = (object: any): string => {
      let str = '';

      // tslint:disable-next-line:forin
      for (const tmp in object) {
        const x = object[tmp];
        str += '&' + tmp + (x == null ? '' : '=' + x);
      }

      // Sostituisco il primo & con ?
      return str === '' ? str : '?' + str.substring(1, str?.length );
    }

}

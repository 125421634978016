import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import {catchError, switchMap, tap, timeout} from 'rxjs/operators';
import LoginCredential from '../utils/models/credential.model';
import { ApiService } from './rest.service';


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    isLoggedIn = false;

    constructor(
        private apiService: ApiService
    ){

    }






    /*
    *   ReloadLogin
    */
    public isLoginExpired(): boolean {
        // Controllo se esiste un token e se e' ancora valido
        const token = localStorage.getItem('XXTOKENXX');

        if ( token ){
            const exp: string = localStorage.getItem( 'XXEXPIRESXX' ) || '0';
            let expires: number;

            try{
              expires = parseInt( exp, 10 );
            } catch ( e ) {
              console.error( e );
              return false;
            }

            if ( isNaN( expires ) || ( expires < new Date().getTime() ) ){
                localStorage.removeItem( 'XXTOKENXX' );
                return true;
            }
            else
                {return false; }
        }

        return true;
    }






    /*
    *   Funzione per la formattazione dell'errore durante il login
    */
    private catchLoginError( httpError: HttpErrorResponse ): Observable<string> {
        let errorResponse = '';

        if ( typeof httpError === 'string' ) {
          errorResponse = httpError;
        } else {
          const error = httpError?.error;

          if ( error?.error === 'invalid_grant' )
          {errorResponse = error?.error_description; }

          // else if( httpError.status === 0 )
          if ( errorResponse === '')
          {errorResponse = 'Servizio non raggiungibile!'; }
        }

        return throwError( errorResponse );
    }






    /*
    *   Funzione per recuperare tutte le credenziali
    */
    private setUserInfo( apiLoginResponse: any ): Observable<string> {
        this.isLoggedIn = true;
        localStorage.setItem(    'XXTOKENXX', `${apiLoginResponse.token_type} ${apiLoginResponse.access_token}`);
        localStorage.setItem(     'XXUSERXX', `${apiLoginResponse.name} ${apiLoginResponse.surname}`);

        // Calcolo quando scadra' il token
        const expires: number = apiLoginResponse.expires_in;
        const dateExipred = new Date().getTime() + new Date(  expires * 1000 ).getTime();

        localStorage.setItem( 'XXEXPIRESXX', `${ dateExipred }`);
        return of( `Benvenuto` );
    }





    /*
    *
    */
    public login( credentials: LoginCredential ): Observable<any> {
        localStorage.setItem( 'XXCREDENTIALXX', JSON.stringify( credentials ) );

        const headers: HttpHeaders = new HttpHeaders( { 'Content-Type': 'application/x-www-form-urlencoded' } );

        return this.apiService.post('token', `username=${credentials.username}&password=${credentials.password}&grant_type=password&device_type=2`, null, headers )
        .pipe(
            timeout(5000),
            catchError( this.catchLoginError ),
            switchMap( this.setUserInfo ),
            tap( _ => localStorage.setItem( 'XXUSERNAMEXX', `${credentials.username}`) )
        );
    }





  /*
  *
  */
  public logout(): void{
    localStorage.removeItem( 'XXCREDENTIALXX' );
    localStorage.removeItem( 'XXTOKENXX' );
    localStorage.removeItem( 'XXUSERXX' );
    localStorage.removeItem( 'XXEXPIRESXX' );
  }





  /*
  *
  */
  getUsername = () => localStorage.getItem('XXUSERNAMEXX');





  /*
  *
  */
  getUserNameSurname = () => localStorage.getItem('XXUSERNAMEXX');


}

import { DOCUMENT } from '@angular/common';
import { ComponentFactoryResolver, EventEmitter, Inject, Injectable, ViewContainerRef } from '@angular/core';


@Injectable(
  {
    providedIn:'root'
  }
)
export class AppComponentFactoryService {


  private viewContainerRef:ViewContainerRef;
  

  constructor( ) { }


  setViewContainerRef(ref:ViewContainerRef){
    this.viewContainerRef = ref;
  }

  /**
   *
   * @param comp il componente 
   * @param params i parametri di input e output da instanziare passati come lista chiave valore nomeParametro:Valore
   * @returns ICreatedComponent
   */

  presentComponent(comp:any,params?:any, viewRef:ViewContainerRef = this.viewContainerRef){
    // presentComponent(comp:any,params?:any):ICreatedComponent{
    // debugger

    let emitters = {};

    if(!viewRef){
      console.error("Non hai impostato la viewcontainerref chiama setViewContainerRef"); 
      return {component:null,emitters:[]}
    }
    
    let component  = viewRef.createComponent(comp);
    
    //Mappo i parametri del componente
    if(params){
      Object.keys(params).forEach(key=>{
        component.instance[key] = params[key];
      })
      // component.instance = { ...component.instance, ..params }
    }

    //Controllo se ci sono eventemitter nel caso li inserisco nell'oggetto
    Object.keys(component.instance).forEach((key)=>{
      if(component.instance[key] instanceof EventEmitter){
        emitters[key] = component.instance[key];
      }
    })

    return {"component":component,"emitters":emitters};
  }

  removeComponent(modal){
    modal.destroy();
  }
}
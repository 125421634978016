import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { AppComponentFactoryService } from './services/elementFactory/AppComponentFactory.service';

@Component({
  selector: 'app-root',
  template: `    
    <router-outlet></router-outlet>
    <div #vitualContainer></div>
  `,
})
export class AppComponent implements AfterViewInit {
  @ViewChild('vitualContainer', { read: ViewContainerRef, static: true }) vitualContainer: ViewContainerRef
  
  constructor(public AppComponentFactoryService:AppComponentFactoryService){
  }

  ngAfterViewInit(): void {
    this.AppComponentFactoryService.setViewContainerRef(this.vitualContainer)

  }
  title = 'ecosmartweb';
}

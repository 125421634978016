import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import locale_it from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AnagraficaControllerService } from './services/controller/anagrafica-controller.service';
import { RestModule } from './shared/modules/rest-module/rest-module.module';
registerLocaleData(locale_it);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    RestModule,
    HttpClientModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "it-IT" }, //replace "en-US" with your locale
    
    //
    AnagraficaControllerService
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { catchError, debounce, debounceTime, first, interval, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { AnagraficaModel, TipologiaUtenteModel } from 'src/app/core/models/AnagraficaModel';
import { ApiService } from 'src/app/shared/modules/rest-module/rest-module.module';
import { environment } from 'src/environments/environment';


@Injectable()
export class AnagraficaControllerService {


  constructor(private apiService: ApiService) { }



  public GET(query) {
    return this.apiService.get<any>('api/Anagrafica/Lista', query).pipe(
      first()
    )
  }


  public GET_ASSOCIAZIONI(query) {
    return this.apiService.get<any>('api/Anagrafica/ListaAssociazioni', query).pipe(
      first()
    )
  }


  public POST(model) {
    return this.apiService.post<any>('api/Anagrafica/Salva', model).pipe(
      first()
    )
  }


  public POST_ANAGRAFICA_PER_FAMIGLIA_PER_IMMOBILE(model) {

    model = {
      IDAnagraficaPerFamigliaPerImmobile:model.ID,
      CodFamiglia: model.CodFamiglia,
      IDAnagraficaPrincipale: model.IDAnagraficaPrincipale ?? model.AnagraficaPrincipale?.ID,
      IDAnagraficaSecondaria: model.IDAnagraficaSecondari ?? model.AnagraficaSecondaria?.ID,
      IDCategoriaUtenza: model.IDCategoriaUtenza ?? model.CategoriaUtenza?.ID,
      IDComune: model.IDComune ?? model.Comune?.ID,
      IDImmobile: model.IDImmobile ?? model.Immobile?.ID
    }

    return this.apiService.post<any>('api/Anagrafica/SalvaNucleoFamigliare', model).pipe(
      first()
    )
  }


  public DELETE(query) {
    return this.apiService.delete<any>('api/Anagrafica/Delete', { ID: query, Cancellato: true }).pipe(
      first()
    )
  }

  public DELETE_PER_FAMIGLIA_PER_IMMOBILE(query) {
    return this.apiService.delete<any>('api/Anagrafica/DeleteAssociazioneFamigliare', { ID: query.ID, Cancellato: true }).pipe(
      first()
    )
  }




  public RIPRISTINA(query) {
    return this.apiService.delete<any>('api/Anagrafica/Delete', { ID: query, Cancellato: false }).pipe(
      first()
    )
  }


  public GET_NUCLEOFAMILIARE(query) {
    return this.apiService.get<any>('api/Anagrafica/ListaFamigliari', query).pipe(
      first()
    )
  }


  public DELETE_anagraficaDaNucleoFamiliare(query) {
    return this.apiService.delete<any>('api/Anagrafica/Delete', { ...query, Cancellato: true }).pipe(
      first()
    )
  }

  public GET_TIPOLOGIA(query) {
    return this.apiService.get<any>('api/Anagrafica/Tipologie', query).pipe(
      first()
    )
  }
}
